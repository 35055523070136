<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Portfolio</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Area  -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--20 mb_sm--0">
              <h2 class="heading-title">Featured Projects</h2>
              <p>
                Here are some brands we recently helped.
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <Portfolio />
      </v-container>
    </div>
    <!-- Start Portfolio Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import Portfolio from "../../components/portfolio/PortfolioPage";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      Header,
      Portfolio,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Portfolio",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
