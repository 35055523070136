<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(project, i) in projects"
      :key="i"
    >
      <div class="portfolio">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="require('../../assets/images/portfolio/' + project.key + '/thumb.jpg')"></v-img>
          <v-img class="bg-blr-image" :src="require('../../assets/images/portfolio/' + project.key + '/thumb.jpg')"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ project.meta }}</p>
            <h4>
              <router-link :to="'/portfolio/' + project.key">{{ project.name }}</router-link>
            </h4>
            <div class="portfolio-button">
              <router-link class="rn-btn" :to="'/portfolio/' + project.key"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
import data from "../../data/portfolio.json";

export default {
  data() {
    return {
      projects: data.projects
    };
  }
};
</script>
